<script setup lang="ts">
import Input from '~/components/forms/Input.vue';
import { sleep } from '~/lib/sleep';

const generalStore = useGeneralStore();

const formRef = ref<HTMLFormElement | null>(null);
const form = reactive({
  email: '',
});
const subscribing = ref(false);
const subscribed = ref(false);

async function subscribe() {
  subscribing.value = true;

  try {
    await generalStore.subscribeToNewsletter({ email: form.email });

    subscribed.value = true;

    await sleep(1_000);

    form.email = '';
  } catch (error) {
  } finally {
    subscribing.value = false;
  }
}
</script>

<template>
  <div class="newsletter">
    <h5>{{ $t('layout.footer.newsletter.title') }}</h5>

    <form ref="formRef" enctype="multipart/form-data">
      <Input
        v-model="form.email"
        theme="light"
        name="email"
        type="email"
        autocomplete="email"
        :placeholder="$t('layout.footer.newsletter.your-email')"
      />

      <Button
        :disabled="subscribing"
        :loading="subscribing"
        :success="subscribed"
        @click="subscribe"
      >
        {{ $t('layout.footer.newsletter.send') }}
      </Button>
    </form>

    <a
      target="_blank"
      rel="external nofollow"
      href="https://magazin.portu.cz/newslettery/portu-gallery-newsletter/"
    >
      {{ $t('layout.footer.newsletter.archive') }}
    </a>
  </div>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 1.25rem;
}

form {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;

  margin-bottom: 1.25rem;
}

.text-field__container {
  width: 100%;
}

a {
  color: var(--red-basic);

  &:visited {
    color: var(--red-dark);
  }

  &:hover {
    color: var(--red-bright);
  }
}
</style>
