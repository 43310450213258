<script setup lang="ts">
import Newsletter from './Newsletter.vue';
import ContactsAndMedia from './ContactsAndMedia.vue';
import Copyright from './Copyright.vue';

const localePath = useLocalePath();
const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);

const links = computed(() => ({
  column1: [
    {
      path: 'investments-offer',
      text: 'layout.links.instruments-offer',
    },
    {
      path: 'how-does-it-work',
      text: 'layout.links.how-does-it-work',
    },
    {
      path: 'about',
      text: 'layout.links.about',
    },
    {
      external: true,
      path: 'https://magazin.portu.cz/investovani-od-a-do-z/sberatelske-predmety/',
      text: 'layout.links.blog',
    },
    {
      path: 'security',
      text: 'layout.links.security',
    },
    ...(isLoggedIn.value
      ? [
          {
            path: 'dashboard-gifts-and-benefits-vouchers',
            text: 'layout.links.vouchers',
          },
        ]
      : []),
  ],
  column2: [
    {
      path: 'faq',
      text: 'layout.links.faq',
    },
    {
      path: 'contractual-documentation',
      text: 'layout.links.contractual-documentation',
    },
    {
      path: 'annual-reports',
      text: 'layout.links.annual-reports',
    },
    {
      path: 'element',
      text: 'layout.links.element',
    },
    {
      path: 'privacy-settings',
      text: 'layout.links.privacy-settings',
    },
    {
      path: 'obligatory-published-information',
      text: 'layout.links.obligatory-published-information',
    },
    {
      external: true,
      path: 'https://affiliate.portu.cz/reklamni-kampane/detail/2/',
      text: 'layout.links.affiliate',
    },
    {
      path: 'contact',
      text: 'layout.links.contact',
    },
  ],
}));
</script>

<template>
  <footer class="section--top-radius background--light">
    <div class="container">
      <div class="row">
        <div class="col column column--newsletter">
          <Newsletter />
        </div>

        <div class="col column column--1">
          <ul>
            <li v-for="link in links.column1" :key="link.text">
              <a v-if="!!link.external" :href="link.path" target="_blank" rel="external nofollow">{{
                $t(link.text)
              }}</a>
              <NuxtLink v-else :to="localePath(link.path)">{{ $t(link.text) }}</NuxtLink>
            </li>
          </ul>
        </div>

        <div class="col column column--2">
          <ul>
            <li v-for="link in links.column2" :key="link.text">
              <a v-if="!!link.external" :href="link.path" target="_blank" rel="external nofollow">{{
                $t(link.text)
              }}</a>
              <NuxtLink v-else :to="localePath(link.path)">{{ $t(link.text) }}</NuxtLink>
            </li>
          </ul>
        </div>

        <div class="col column column--contacts-links">
          <ContactsAndMedia />
        </div>
      </div>
    </div>

    <Copyright />
  </footer>
</template>

<style lang="scss" scoped>
footer {
  padding-top: 3.125rem;

  background-color: var(--white-darkened);

  .row {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1.25rem;
  }

  .column--newsletter {
    order: 2;
    flex: 1 0 100%;

    width: 100%;

    @media (min-width: 768px) {
      order: 1;

      flex: 1 0 auto;
      width: calc(33.33333333% - var(--gap));
    }
  }

  .column--1 {
    order: 3;

    width: calc(50% - var(--gap));

    font-size: var(--font-size-sm);

    @media (min-width: 768px) {
      order: 2;
      flex: 0 0 auto;

      padding-left: 8.33333333%;

      width: calc(25% - var(--gap));
    }
  }

  .column--2 {
    order: 4;

    width: calc(50% - var(--gap));

    font-size: var(--font-size-sm);

    @media (min-width: 768px) {
      order: 3;
      flex: 0 0 auto;

      width: calc(16.66666667% - var(--gap));
    }
  }

  .column--contacts-links {
    order: 1;
    flex: 1 0 100%;

    width: 100%;

    @media (min-width: 768px) {
      order: 4;

      flex: 0 0 auto;
      width: calc(25% - var(--gap));
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }

  a {
    --color: var(--black);

    text-decoration: none;
    font-size: var(--font-size-xs);
  }
}
</style>
